import { ApiService } from '../../common/infrastructure/api.service';
import { AccountService } from '../../account/application/account.service';
import { Injectable, signal } from '@angular/core';
import {
  CreateFreightWithStopsAndAssignationsCommand,
  FreightAssignationType,
  FreightDocumentType,
  FreightSource,
  FreightStopPayload,
  FreightStopType,
  FreightTransportGoodsType,
  FreightType,
  TemperatureUnit,
} from '@okcargo/command-processor';
import { CriteriaPayload, FilterCombinator, FilterComparator, FreightFieldNames, OrderPayload, OrderType, PartyPayload, SearchInProgressFreightsQuery, SearchNotInProgressFreightsQuery } from '@okcargo/query-processor';
import { BehaviorSubject, from, map } from 'rxjs';
import { IAccount, ICompany } from 'src/app/account/domain/account.model';
import { ICostCenter } from 'src/app/freight/domain/post-freight.model';
import { v4 as uuidv4 } from 'uuid';
import { FreightQueryService } from 'src/app/freight/infrastructure/freight-query.service';
import { FreightCommandService } from 'src/app/freight/infrastructure/freight-command.service';
import { IFreightBasic, getEmptyDefaultFreightBasic, EFreightStatus } from 'src/app/freight/domain/freight.model';
import { transformToIFreightBasic } from 'src/app/freight/infrastructure/freight-mapper';

@Injectable({
  providedIn: 'root',
})
export class FreightPostService {
  private readonly _endpointCostCenters = '/services/uaa/api/company-carriers';

  page: number = 0;
  size: number = 300;

  private _costCentersSubject: BehaviorSubject<ICostCenter[]> =
    new BehaviorSubject<ICostCenter[]>([]);

  private _accountCompany: ICompany | null = null;

  private account!: IAccount;

  private _freightsPostedPending$ = new BehaviorSubject<IFreightBasic[]>([]);
  private _freightsPostedInProgress$ = new BehaviorSubject<IFreightBasic[]>([]);
  private _freightsPostedFinished$ = new BehaviorSubject<IFreightBasic[]>([]);

  public freightsPostedNotFinishedLoading = signal(true);
  public freightsPostedFinishedLoading = signal(true);

  constructor(
    private accountService: AccountService,
    private freightQueryService: FreightQueryService,
    private freightCommandService: FreightCommandService,
    private apiService: ApiService, // TODO review, it's necessary???
  ) {
    this.accountService.account$.subscribe((account) => {
      if (account.company) {
        this._accountCompany = account.company; // TODO refactor this
        this.account = account;
      }
    });
    // TODO remove function getCostCenters(). Get data form accountService
    // this.getCostCenters();
  }

  get freightsPostedPending$() {
    return this._freightsPostedPending$;
  }

  get freightsPostedInProgress$() {
    return this._freightsPostedInProgress$;
  }

  get freightsPostedFinished$() {
    return this._freightsPostedFinished$;
  }

  public refreshFreightsPostedNotFinished(): void {
    this.getFreightsPostedNotFinished();
  }

  public refreshFreightsPostedFinished(): void {
    this.getFreightsPostedFinished();
  }

  // TODO
  /*
  De aquí hay que sacar dos observables: uno con las cargas que aún no tienen precio y otro con las que tienen precio pero no están asignadas
  */
  private getFreightsPostedNotFinished(): void {
    const query = {
      criteriaPayload: {
        order: [
          {
            field: FreightFieldNames.STARTING_AT,
            type: OrderType.ASC,
          } as OrderPayload,
        ],
        // only freights assigned to others
        filters: [
          {
            combinator: FilterCombinator.NONE,
            field: FreightFieldNames.CARRIER_ID,
            comparator: FilterComparator.NEQ,
            value: this.account.company.id,
            group: null,
          },
        ],
        pageIndex: this.page,
        pageSize: this.size,
      } as unknown as CriteriaPayload,
    } as SearchInProgressFreightsQuery;

    from(this.freightQueryService.searchInProgressFreights(query))
      .pipe(
        map((freights) =>
          freights.map((freightBasicResponse): IFreightBasic => {
            const freight: IFreightBasic = getEmptyDefaultFreightBasic();
            return transformToIFreightBasic(freightBasicResponse, freight);
          })
        ),
        map((freights) =>
          {
            // TODO review if freight.status !== EFreightStatus.FINISHED is necessary because there were times when the status of the last stop was not consistent with the freight status
            const pendingFreights = freights.filter(
              (freight) =>
                freight.status === EFreightStatus.OFFER_REQUESTED ||
              freight.status === EFreightStatus.OFFER_SENT
            );

            const inProgressFreights = freights.filter(
              (freight) =>
                freight.status !== EFreightStatus.OFFER_REQUESTED &&
                freight.status !== EFreightStatus.OFFER_SENT
            );

            return { pendingFreights, inProgressFreights };
          }
        )
      )
      .subscribe(({ pendingFreights, inProgressFreights }) => {
        this._freightsPostedPending$.next(pendingFreights);
        this._freightsPostedInProgress$.next(inProgressFreights);
        this.freightsPostedNotFinishedLoading.set(false);
      });
  }

  private getFreightsPostedFinished(): void {
    const query = {
      criteriaPayload: {
        order: [
          {
            field: FreightFieldNames.STARTING_AT,
            type: OrderType.DESC,
          } as OrderPayload,
        ],
        // only freights assigned to others
        filters: [
          {
            combinator: FilterCombinator.NONE,
            field: FreightFieldNames.CARRIER_ID,
            comparator: FilterComparator.NEQ,
            value: this.account.company.id,
            group: null,
          },
        ],
        pageIndex: this.page,
        pageSize: this.size,
      } as unknown as CriteriaPayload,
    } as SearchNotInProgressFreightsQuery;

    from(this.freightQueryService.searchFinishedFreights(query))
      .pipe(
        map((freights) =>
          freights.map((freightBasicResponse): IFreightBasic => {
            const freight: IFreightBasic = getEmptyDefaultFreightBasic();
            return transformToIFreightBasic(freightBasicResponse, freight);
          })
        )
      )
      .subscribe((transformedFreights) => {
        this._freightsPostedFinished$.next(transformedFreights);
        this.freightsPostedFinishedLoading.set(false);
      });
  }




  public get costCenter$() {
    return this._costCentersSubject.asObservable();
  }

  // private getCostCenters(): void {
  //   this.apiService
  //     .get(this._endpointCostCenters, { observe: 'body' })
  //     .pipe(
  //       map((response) => {
  //         console.log('getCostCenters() response', response)
  //         if (Array.isArray(response) && response.length > 0) {
  //           const costCenters = response[0]?.company?.costCenters || [];
  //           return costCenters.map((center: ICostCenter) => ({
  //             id: center.id,
  //             name: center.name
  //           }));
  //         } else return [];
  //       })
  //     )
  //     .subscribe({
  //       next: (costCenters: ICostCenter[]) => {
  //         return this._costCentersSubject.next(costCenters);
  //       },
  //       error: (err) => console.error('Error loading cost centers:', err),
  //     });
  // }




/*** BELOW, NOT IN USE */

  // }

  // move to model? infrastructure?
  // getEmptyFreightUpload(): CreateFreightWithStopsAndAssignationsCommand {
  getEmptyFreightPost() {
    const firstStop = this.getEmptyStop(FreightStopType.LOAD, 0);
    const lasStop = this.getEmptyStop(FreightStopType.UNLOAD, 1);

    const companyData = this.companyData(this._accountCompany);

    return {
      id: uuidv4(),
      type: FreightType.FULL,
      transportInfoPayload: {
        vehicleTypes: [], // "CANVAS" // FORM!!!!
        loadingTypes: [], // "REAR" // FORM!!!!
        transportGoodsType: FreightTransportGoodsType.WASTE,
        exchangePlatform: false, //null,
        transportTemperatureRanges: [],
        transportTemperatureUnit: TemperatureUnit.CELSIUS,
      },
      documentType: FreightDocumentType.ECDP, //"ECDP",
      instructions: '', // FORM!!!!
      deliveryNoteInPaper: false, // FORM!!!!
      carrierObservations: '', // // FORM!!!!
      freightTemplateName: '', // null,
      stops: [firstStop, lasStop],
      assignations: [
        {
          assignationId: uuidv4(),
          assignationType: FreightAssignationType.COLLABORATOR,
          assignedFromPayload: {
            partyPayload: companyData as unknown as PartyPayload,
            costCenterPayload: {
              id: 1008, // FORM!!!!
              m2mReference: 'CENTRO', // FORM!!!!
            },
            deliveryNoteInPaper: true, // FORM!!!!
            freightM2mId: null,
            freightM2mReference: 'referenciaCarga', // FORM!!!!
          },
          assignedToPayload: {
            partyPayload: this.companyData(null) as unknown as PartyPayload,
            costCenterPayload: null,
            freightM2mId: null,
            freightM2mReference: 'Expedición', // FORM!!!!
          },
          pricePayload: null,
          deliveryNoteInPaper: true, // FORM!!!!
          contractual: true,
          fromOkCargo: false,
          toOkCargo: true,
          priceAccepted: false,
        },
      ],
      journeyId: uuidv4(),
      source: FreightSource.WEB_FORM, //'WEB_FORM',
    };
  }

  private getEmptyStop(
    type: FreightStopType,
    position: number
    // ): FreightStopPayload {
  ) {
    return {
      stopId: uuidv4(),
      position: 0, // FORM!!!!
      type: type, // FORM!!!!
      addressPayload: {
        // id: 0, // not necessary
        // national: true, // not necessary
        street: '', // FORM!!!!
        city: '', // FORM!!!!
        postalCode: '', // FORM!!!!
        state: '', // FORM!!!!
        country: '', // FORM!!!!
        pointPayload: {
          latitude: 0, // FORM!!!!
          longitude: 0, // FORM!!!!
        },
      },
      companyPayload: {
        id: 0, // null,
        name: '',
        taxId: '',
        phone: '',
        addressPayload: null,
        m2mId: null,
      },
      since: '2024-08-01T22:00:00.000Z', // FORM!!!!
      until: '2024-08-02T00:00:00.000Z', // FORM!!!!
      tare: null, // FORM!!!!
      grossWeight: null, // FORM!!!!
      netWeight: 1000, // FORM!!!!
      packages: 10, // FORM!!!!
      goodsDescription: 'descripción', // FORM!!!!
      reference: 'refcarga', // FORM!!!!
      unloadedNetWeight: null, // FORM!!!!
      unloadedPackages: null, // FORM!!!!
    };
  }

  // private companyData(): PartyPayload {
  private companyData(accountCompany: ICompany | null) {
    return {
      id: accountCompany?.id ?? null,
      name: accountCompany?.name ?? '',
      taxId: accountCompany?.taxNumber ?? '',
      phone: accountCompany?.phone ?? '',
      addressPayload: {
        id: null,
        street: accountCompany?.streetAddress ?? '',
        city: accountCompany?.city ?? '',
        postalCode: accountCompany?.postalCode ?? '',
        state: accountCompany?.stateProvince ?? '',
        country: accountCompany?.country ?? '',
        pointPayload: null,
      },
      m2mId: null,
    };
  }
}
